export const PLAN_TOKENS = {
  free: 500,
  basic: 2500,
  pro: 6000,
  tokenPack: 2500,
} as const;

export const PLANS = {
  basic: {
    id: 'price_1QWLlEG3oXPUVempYx4uM71S',
    name: 'Basic',
    tokens: PLAN_TOKENS.basic,
  },
  pro: {
    id: 'price_1QWLlHG3oXPUVempH34Lhl7s',
    name: 'Pro',
    tokens: PLAN_TOKENS.pro,
  },
  tokenPack: {
    id: 'price_1QRuiLG3oXPUVempjWOAYBYc',
    tokens: PLAN_TOKENS.tokenPack,
  },
} as const;