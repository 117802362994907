import React from 'react';
import { LogOut } from 'lucide-react';

interface LogoutButtonProps {
  onClick: () => void;
}

export default function LogoutButton({ onClick }: LogoutButtonProps) {
  return (
    <button
      onClick={onClick}
      className="flex items-center space-x-2 px-3 py-2 text-gray-700 hover:text-gray-900 rounded-md hover:bg-gray-100 transition-colors"
    >
      <LogOut className="h-4 w-4" />
      <span className="text-sm">Logout</span>
    </button>
  );
}