import React from 'react';
import { Video } from 'lucide-react';

interface LogoProps {
  onClick: () => void;
}

export default function Logo({ onClick }: LogoProps) {
  return (
    <div 
      className="flex items-center space-x-2 cursor-pointer" 
      onClick={onClick}
    >
      <Video className="h-8 w-8 text-primary-500" />
      <span className="text-2xl font-bold text-gray-900">RealTreat</span>
    </div>
  );
}