import { handlePlanUpdate } from '../subscription/planManager';

export async function handleSubscriptionWebhook(
  userId: string,
  fullName: string,
  email: string,
  stripeCustomerId: string | null
): Promise<void> {
  try {
    const response = await fetch('https://hook.eu2.make.com/xja87v35fwgbisldv9asqtyu28iawvfq', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        fullName,
        email,
        stripeCustomerId
      })
    });

    if (!response.ok) {
      throw new Error('Failed to send webhook');
    }

    const priceId = (await response.text()).trim();
    await handlePlanUpdate(userId, priceId);
  } catch (error) {
    console.error('Webhook error:', error);
    throw error;
  }
}