import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { useTokens } from '../contexts/TokenContext';
import NavigationConfirmModal from './NavigationConfirmModal';
import UserDashboardModal from './UserDashboardModal';
import TokenDisplay from './topbar/TokenDisplay';
import UserSection from './topbar/UserSection';
import ErrorDisplay from './topbar/ErrorDisplay';
import LogoutButton from './topbar/LogoutButton';
import Logo from './topbar/Logo';

export default function TopBar() {
  const navigate = useNavigate();
  const location = useLocation();
  const { logout } = useAuth();
  const { error } = useTokens();
  const [showConfirmModal, setShowConfirmModal] = useState<{
    isOpen: boolean;
    action: 'home' | 'logout';
  }>({ isOpen: false, action: 'home' });
  const [showUserDashboard, setShowUserDashboard] = useState(false);

  const isWizardPage = location.pathname === '/create-treatment';

  const handleLogoClick = () => {
    if (isWizardPage) {
      setShowConfirmModal({ isOpen: true, action: 'home' });
    } else {
      navigate('/dashboard');
    }
  };

  const handleLogoutClick = () => {
    if (isWizardPage) {
      setShowConfirmModal({ isOpen: true, action: 'logout' });
    } else {
      handleLogout();
    }
  };

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/login');
    } catch (error) {
      console.error('Logout error:', error);
    }
  };

  const handleConfirm = () => {
    if (showConfirmModal.action === 'home') {
      navigate('/dashboard');
    } else {
      handleLogout();
    }
    setShowConfirmModal({ isOpen: false, action: 'home' });
  };

  return (
    <>
      <nav className="bg-white/80 backdrop-blur-md fixed w-full z-10 shadow-sm">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between h-16">
            <div className="flex items-center">
              <Logo onClick={handleLogoClick} />
            </div>
            <div className="flex items-center space-x-4">
              <TokenDisplay />
              <ErrorDisplay error={error} />
              <UserSection onShowDashboard={() => setShowUserDashboard(true)} />
              <LogoutButton onClick={handleLogoutClick} />
            </div>
          </div>
        </div>
      </nav>

      <NavigationConfirmModal
        isOpen={showConfirmModal.isOpen}
        onClose={() => setShowConfirmModal({ isOpen: false, action: 'home' })}
        onConfirm={handleConfirm}
        title={showConfirmModal.action === 'home' ? 'Leave Page?' : 'Logout?'}
      />

      <UserDashboardModal
        isOpen={showUserDashboard}
        onClose={() => setShowUserDashboard(false)}
      />
    </>
  );
}