import { doc, getDoc } from 'firebase/firestore';
import { db } from '../config/firebase';
import { handleSubscriptionWebhook } from '../utils/webhook/webhookService';
import { checkAndReloadTokens } from '../utils/tokens/tokenReload';

export async function checkSubscriptionStatus(userId: string): Promise<void> {
  try {
    const userRef = doc(db, 'users', userId);
    const userDoc = await getDoc(userRef);
    
    if (!userDoc.exists()) {
      throw new Error('User not found');
    }

    const userData = userDoc.data();
    
    // First check subscription with webhook
    await handleSubscriptionWebhook(
      userId,
      userData.fullName,
      userData.email,
      userData.stripeCustomerId
    );

    // Then check and reload tokens if needed
    await checkAndReloadTokens(userId);
  } catch (error) {
    console.error('Error checking subscription:', error);
    throw error;
  }
}