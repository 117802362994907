export const PLAN_TOKENS = {
  free: 500,
  'Basic Plan': 2500,
  'Pro Plan': 6000,
} as const;

export const PRICE_TO_PLAN = {
  'price_1QWLlEG3oXPUVempYx4uM71S': 'Basic Plan',
  'price_1QWLlHG3oXPUVempH34Lhl7s': 'Pro Plan'
} as const;

export type PlanType = keyof typeof PLAN_TOKENS;