import React from 'react';
import { AlertCircle } from 'lucide-react';

interface ErrorDisplayProps {
  error: string | null;
}

export default function ErrorDisplay({ error }: ErrorDisplayProps) {
  if (!error) return null;

  return (
    <div className="flex items-center text-red-600">
      <AlertCircle className="h-4 w-4 mr-1" />
      <span className="text-sm">{error}</span>
    </div>
  );
}