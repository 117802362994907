import React, { createContext, useContext, useState, useEffect } from 'react';
import {
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
  User,
  createUserWithEmailAndPassword,
} from 'firebase/auth';
import { doc, setDoc, getDoc, onSnapshot } from 'firebase/firestore';
import { auth, db } from '../config/firebase';
import { PLAN_TOKENS } from '../config/plans';
import { sendSignupWebhook } from '../utils/webhook';
import { checkSubscriptionStatus } from '../services/subscriptionService';

interface UserProfile {
  email: string;
  fullName: string;
  createdAt: Date;
  updatedAt: Date;
  tokens: number;
  currentPlan: keyof typeof PLAN_TOKENS;
  subscriptionStatus?: string;
  stripeCustomerId?: string;
  stripeSubscriptionId?: string;
}

interface AuthContextType {
  user: User | null;
  userProfile: UserProfile | null;
  loading: boolean;
  error: string | null;
  loginWithEmail: (email: string, password: string) => Promise<void>;
  signup: (email: string, password: string, fullName: string) => Promise<void>;
  logout: () => Promise<void>;
}

const AuthContext = createContext<AuthContextType | null>(null);

export function useAuth() {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [user, setUser] = useState<User | null>(null);
  const [userProfile, setUserProfile] = useState<UserProfile | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      setUser(user);
      setLoading(true);
      setError(null);

      if (user) {
        const userRef = doc(db, 'users', user.uid);
        
        try {
          // First, check if user document exists
          const userDoc = await getDoc(userRef);
          
          if (!userDoc.exists()) {
            // Create initial user profile if it doesn't exist
            const initialProfile = {
              email: user.email,
              fullName: user.displayName || '',
              createdAt: new Date(),
              updatedAt: new Date(),
              lastTokenReset: new Date(),
              tokens: PLAN_TOKENS.free,
              currentPlan: 'free' as const,
              subscriptionStatus: 'none'
            };
            
            await setDoc(userRef, initialProfile);
          }

          // Check subscription status
          await checkSubscriptionStatus(user.uid);

          // Set up real-time listener for user profile
          const unsubscribeProfile = onSnapshot(userRef, 
            (doc) => {
              if (doc.exists()) {
                const data = doc.data();
                setUserProfile({
                  ...data,
                  createdAt: data.createdAt.toDate(),
                  updatedAt: data.updatedAt.toDate(),
                  lastTokenReset: data.lastTokenReset?.toDate(),
                } as UserProfile);
              }
              setLoading(false);
            },
            (error) => {
              console.error('Error fetching user profile:', error);
              setError('Failed to load user profile');
              setLoading(false);
            }
          );

          return () => unsubscribeProfile();
        } catch (error) {
          console.error('Error setting up profile:', error);
          setError('Failed to set up user profile');
          setLoading(false);
        }
      } else {
        setUserProfile(null);
        setLoading(false);
      }
    });

    return () => unsubscribe();
  }, []);

  const loginWithEmail = async (email: string, password: string) => {
    try {
      setError(null);
      const result = await signInWithEmailAndPassword(auth, email, password);
      await checkSubscriptionStatus(result.user.uid);
    } catch (error: any) {
      console.error('Login error:', error);
      setError(error.message || 'Failed to log in');
      throw error;
    }
  };

  const signup = async (email: string, password: string, fullName: string) => {
    try {
      setError(null);
      const stripeCustomerId = await sendSignupWebhook(fullName, email);
      const result = await createUserWithEmailAndPassword(auth, email, password);
      
      const now = new Date();
      await setDoc(doc(db, 'users', result.user.uid), {
        email,
        fullName,
        createdAt: now,
        updatedAt: now,
        lastTokenReset: now,
        tokens: PLAN_TOKENS.free,
        currentPlan: 'free',
        subscriptionStatus: 'none',
        stripeCustomerId
      });
    } catch (error: any) {
      console.error('Signup error:', error);
      setError(error.message || 'Failed to sign up');
      throw error;
    }
  };

  const logout = async () => {
    try {
      setError(null);
      await signOut(auth);
    } catch (error: any) {
      console.error('Logout error:', error);
      setError(error.message || 'Failed to log out');
      throw error;
    }
  };

  const value = {
    user,
    userProfile,
    loading,
    error,
    loginWithEmail,
    signup,
    logout,
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
}