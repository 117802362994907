import React from 'react';
import { Coins, Loader2 } from 'lucide-react';
import { useTokens } from '../../contexts/TokenContext';

const LOW_TOKEN_THRESHOLD = 100;

export default function TokenDisplay() {
  const { tokens, loading } = useTokens();
  const isLowTokens = tokens < LOW_TOKEN_THRESHOLD;

  return (
    <div 
      className={`flex items-center ${
        isLowTokens ? 'bg-red-50' : 'bg-secondary-50'
      } rounded-full px-4 py-1 transition-colors`}
    >
      <Coins className={`h-4 w-4 ${
        isLowTokens ? 'text-red-500' : 'text-secondary-500'
      } mr-2`} />
      {loading ? (
        <Loader2 className="h-4 w-4 animate-spin text-secondary-500" />
      ) : (
        <span className={`text-sm font-medium ${
          isLowTokens ? 'text-red-700' : 'text-secondary-700'
        }`}>
          {tokens} tokens
        </span>
      )}
    </div>
  );
}