import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../../config/firebase';
import { getTokensForPlan, shouldReloadTokens } from './helpers';

export async function checkAndReloadTokens(userId: string): Promise<void> {
  try {
    const userRef = doc(db, 'users', userId);
    const userDoc = await getDoc(userRef);

    if (!userDoc.exists()) {
      throw new Error('User not found');
    }

    const userData = userDoc.data();
    const lastTokenReset = userData.lastTokenReset?.toDate();
    const currentPlan = userData.currentPlan || 'free';

    if (!lastTokenReset) return;

    if (shouldReloadTokens(lastTokenReset)) {
      const newTokenAmount = getTokensForPlan(currentPlan);
      const now = new Date();

      await updateDoc(userRef, {
        tokens: newTokenAmount,
        lastTokenReset: now,
        updatedAt: now
      });
    }
  } catch (error) {
    console.error('Error checking/reloading tokens:', error);
    throw error;
  }
}