import React from 'react';
import { User, ArrowUpCircle } from 'lucide-react';
import { useAuth } from '../../contexts/AuthContext';

interface UserSectionProps {
  onShowDashboard: () => void;
}

export default function UserSection({ onShowDashboard }: UserSectionProps) {
  const { user, userProfile } = useAuth();
  const isFreeUser = userProfile?.currentPlan === 'free';

  return (
    <button
      onClick={onShowDashboard}
      className={`flex items-center space-x-2 px-3 py-2 rounded-md transition-colors ${
        isFreeUser 
          ? 'bg-red-50 text-red-600 hover:bg-red-100'
          : 'text-gray-700 hover:text-gray-900 hover:bg-gray-100'
      }`}
    >
      {isFreeUser ? (
        <>
          <ArrowUpCircle className="h-4 w-4" />
          <span className="font-medium">Upgrade Subscription</span>
        </>
      ) : (
        <>
          <User className="h-4 w-4" />
          <span className="text-sm">{user?.email}</span>
        </>
      )}
    </button>
  );
}