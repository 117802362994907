import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../../config/firebase';
import { PLAN_TOKENS, PRICE_ID_TO_PLAN } from './constants';
import type { PlanType, SubscriptionUpdate } from './types';

export function determinePlanFromPriceId(priceId: string): PlanType {
  return PRICE_ID_TO_PLAN[priceId] || 'free';
}

export function shouldUpdatePlan(currentPlan: PlanType, newPlan: PlanType): boolean {
  if (currentPlan === newPlan) return false;
  if (newPlan === 'Pro Plan') return true;
  if (newPlan === 'Basic Plan' && currentPlan === 'free') return true;
  return false;
}

export async function updateUserPlan(
  userId: string, 
  newPlan: PlanType
): Promise<void> {
  const userRef = doc(db, 'users', userId);
  const update: SubscriptionUpdate = {
    currentPlan: newPlan,
    tokens: PLAN_TOKENS[newPlan],
    lastTokenReset: new Date(),
    updatedAt: new Date()
  };
  
  await updateDoc(userRef, update);
}

export async function handlePlanUpdate(userId: string, priceId: string): Promise<void> {
  const userRef = doc(db, 'users', userId);
  const userDoc = await getDoc(userRef);

  if (!userDoc.exists()) {
    throw new Error('User not found');
  }

  const userData = userDoc.data();
  const currentPlan = userData.currentPlan as PlanType || 'free';
  const newPlan = determinePlanFromPriceId(priceId);

  if (shouldUpdatePlan(currentPlan, newPlan)) {
    await updateUserPlan(userId, newPlan);
  }
}