import { PlanType } from './constants';
import { PLAN_TOKENS } from './constants';

export function getTokensForPlan(plan: string): number {
  const planKey = plan as PlanType;
  return PLAN_TOKENS[planKey] || PLAN_TOKENS.free;
}

export function shouldReloadTokens(lastResetDate: Date): boolean {
  const today = new Date();
  const monthDiff = 
    (today.getFullYear() - lastResetDate.getFullYear()) * 12 + 
    (today.getMonth() - lastResetDate.getMonth());
    
  return monthDiff >= 1;
}